import React from 'react';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <div>
          <div style={{
        backgroundColor: 'black',
        padding: '20px',
        textAlign: 'center'
      }}>
        <h1 style={{
          color: 'white',
          fontSize: '24px',
          margin: '0'
        }}>
          Cricket Combat
        </h1>
      </div>
           
      <div className="content-container" style={{   padding: '20px', 
          maxWidth: '800px', 
          margin: '0 auto', 
          lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: November 13, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Cricket Combat! Shouryanga Technologies LLP ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information when you use our app, Cricket Combat.
      </p>

      <h2>2. Information We Collect</h2>
      <p>To enhance and personalize your experience, we collect the following types of information:</p>

      <h3>a. Email Address</h3>
      <p>
        We collect your email address when you register or sign in. This allows us to communicate with you, manage your account, and verify your identity for the referral program.
      </p>

      <h3>b. Device ID</h3>
      <p>
        We collect a unique device identifier to help prevent misuse of our referral program. This ID assists us in ensuring that each referral is genuine and in compliance with our program’s terms.
      </p>

      <h3>c. Location</h3>
      <p>
        We may collect location data to show you relevant rewards and offers specific to your region, enhancing the personalization of your experience.
      </p>

      <h3>d. Microphone Access</h3>
      <p>
        We request access to your microphone to enable in-app voice communication in multiplayer modes. This feature lets you connect and communicate with other players in real-time during gameplay.
      </p>

      <h2>3. Why We Collect Your Information</h2>
      <p>Each piece of data serves a specific purpose to improve your experience and maintain a secure app environment:</p>
      <ul>
        <li><strong>Email Address:</strong> Used to manage your account, communicate updates, and secure the referral program.</li>
        <li><strong>Device ID:</strong> Helps prevent unauthorized access and ensures fair use of the referral program.</li>
        <li><strong>Location:</strong> Enables us to provide rewards and offers tailored to your area.</li>
        <li><strong>Microphone Access:</strong> Allows you to communicate with other players in multiplayer modes, enhancing social interaction in the app.</li>
      </ul>

      <h2>4. How We Use Your Information</h2>
      <p>We use the collected information for the following purposes:</p>
      <ul>
        <li><strong>Account Management:</strong> To authenticate your account, verify identity, and manage user access.</li>
        <li><strong>App Functionality and Personalization:</strong> To enhance app performance, personalize rewards, and provide a customized user experience.</li>
        <li><strong>Communication:</strong> To send important updates, such as feature announcements or policy changes.</li>
      </ul>

      <h2>5. Sharing Your Information</h2>
      <p>We value your privacy and do not sell or share your personal information with third parties, except in the following situations:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share limited information with trusted third-party providers who help us operate and maintain the app.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal requirements or respond to lawful requests by public authorities.</li>
      </ul>

      <h2>6. Data Storage and Security</h2>
      <p>
        Your data is securely stored and follows industry standards to protect your information. While we work to protect your information, please note that no security system is completely infallible.
      </p>

      <h2>7. Your Choices and Rights</h2>
      <ul>
        <li><strong>Access and Update:</strong> You may review and update your email address within the app or by contacting us directly.</li>
        <li><strong>Data Deletion:</strong> If you wish to delete your account and associated data, please contact us at <a href="mailto:support@cricketcombat.com">support@cricketcombat.com</a>. We will delete your information within a reasonable time, as required by law.</li>
        <li><strong>Permissions:</strong> You can control access to certain data, such as location and microphone, through your device’s settings. Please note that disabling these permissions may limit app functionality.</li>
      </ul>

      <h2>8. Children’s Privacy</h2>
      <p>
        Cricket Combat is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If you believe we have collected data from a child under 13, please contact us.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Any changes will be posted within the app, with the "Effective Date" at the top indicating the latest revision. Continued use of Cricket Combat after any changes constitutes acceptance of the revised Privacy Policy.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        For questions or concerns about this Privacy Policy, please reach out to us at:
      </p>
      <p>
        <strong>Shouryanga Technologies LLP</strong><br />
        <strong>Email:</strong> <a href="mailto:support@cricketcombat.com">support@cricketcombat.com</a>
      </p>
    </div>
   <Footer/>
    </div>

  );
};

export default PrivacyPolicy;