import React from 'react';
import Footer from './Footer';

const TermsAndConditions = () => {
  return (
    <div>
      <div style={{
        backgroundColor: 'black',
        padding: '20px',
        textAlign: 'center'
      }}>
        <h1 style={{
          color: 'white',
          fontSize: '24px',
          margin: '0'
        }}>
          Cricket Combat
        </h1>
      </div>

      <div className="content-container" style={{   padding: '20px', 
          maxWidth: '800px', 
          margin: '0 auto', 
          lineHeight: '1.6' }}>
        <h2>Terms and Conditions for Cricket Combat</h2>
        <p>Effective Date: November 13, 2024</p>

        <p>
          Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the Cricket Combat app (the “Service”) operated by Shouryanga Technologies LLP (“we,” “our,” “us”). By accessing or using Cricket Combat, you agree to be bound by these Terms.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By downloading, installing, or using Cricket Combat, you agree to comply with and be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.
        </p>

        <h2>2. Eligibility</h2>
        <p>
          Cricket Combat is intended for users who are 13 years of age or older. By using the Service, you confirm that you meet this age requirement.
        </p>

        <h2>3. User Accounts</h2>
        <ul>
          <li><strong>Account Creation:</strong> To access certain features, you may need to create an account. You agree to provide accurate and complete information during registration and to keep this information updated.</li>
          <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account and password. We will not be liable for any loss or damage resulting from your failure to safeguard your account.</li>
          <li><strong>Account Termination:</strong> We reserve the right to suspend or terminate your account at our discretion if we determine that you have violated these Terms or engaged in activities that may harm the Service or other users.</li>
        </ul>

        <h2>4. Permitted Use</h2>
        <ul>
          <li><strong>Personal and Non-Commercial Use:</strong> Cricket Combat is for personal, non-commercial use only. You may not use the app for any unauthorized or illegal purposes.</li>
          <li><strong>Prohibited Conduct:</strong> You agree not to misuse the Service, including but not limited to:</li>
          <ul>
            <li>Using the app to engage in fraudulent activities, such as manipulating referral programs.</li>
            <li>Disrupting or interfering with the Service or servers.</li>
            <li>Attempting to access another user’s account without authorization.</li>
          </ul>
        </ul>

        <h2>5. Referral Program</h2>
        <p>
          Our referral program is designed to reward users for inviting others to join Cricket Combat. We reserve the right to review and audit referral activities. Abuse of the referral program, including creating multiple accounts or using fake referrals, may result in account suspension or termination.
        </p>

        <h2>6. Collection and Use of Data</h2>
        <p>
          Please refer to our Privacy Policy for information on how we collect, use, and protect your data, including personal information such as your email address, device ID, location, and microphone access.
        </p>

        <h2>7. Intellectual Property</h2>
        <ul>
          <li><strong>Ownership:</strong> Cricket Combat and all related content, including but not limited to text, graphics, logos, and software, are the intellectual property of Shouryanga Technologies LLP and are protected by applicable copyright, trademark, and other laws.</li>
          <li><strong>Limited License:</strong> We grant you a limited, non-exclusive, non-transferable license to use the app for personal, non-commercial purposes. You may not copy, modify, distribute, or create derivative works based on any part of Cricket Combat without prior written consent.</li>
        </ul>

        <h2>8. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Shouryanga Technologies LLP, its affiliates, and its licensors shall not be liable for any damages, including but not limited to indirect, incidental, or consequential damages, arising from your use of or inability to use Cricket Combat.
        </p>

        <h2>9. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Shouryanga Technologies LLP, its affiliates, and its licensors from any claims, damages, liabilities, and expenses arising from your use of Cricket Combat, violation of these Terms, or infringement of any third-party rights.
        </p>

        <h2>10. Modification of Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. Changes will be posted within the app, and the “Effective Date” will indicate the latest update. Your continued use of Cricket Combat after any changes constitutes acceptance of the updated Terms.
        </p>

        <h2>11. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [Your Country/State].
        </p>

        <h2>12. Contact Us</h2>
        <p>
          If you have any questions or concerns about these Terms and Conditions, please contact us:
        </p>
        <p>
          <strong>Shouryanga Technologies LLP</strong><br />
          <strong>Email:</strong> <a href="mailto:support@cricketcombat.com">support@cricketcombat.com</a>
        </p>
      </div>
      <Footer/>
    </div>
  );
};

export default TermsAndConditions;