import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h2><span style={{ fontWeight: 'bold' }}>Step into  the World of Cricket Combat!</span></h2>
      <p>
        Get ready to relive the thrill of the cricket card games you loved as a kid, but now with an exciting digital twist! <span style={{ fontWeight: 'bold' }}>Cricket Combat</span> brings back the intensity and fun of classic card games, blending nostalgia with a strategic, skill-based gameplay experience. Battle it out with friends or rivals and prove you're the ultimate cricket strategist!
      </p>
    </div>
  );
}

export default About;