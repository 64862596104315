import React, { useState } from 'react';
import './FAQ.css'; // Add a separate CSS file for styling

function FAQ() {
  const [open, setOpen] = useState(null); // Manage which FAQ item is open

  // Function to toggle the answer visibility
  const toggle = (index) => {
    if (open === index) {
      setOpen(null); // Close if it's already open
    } else {
      setOpen(index); // Open the selected item
    }
  };

  // Sample FAQ data
  const faqs = [

    { question: "How do I join a game?", answer: "You can join a game by clicking the 'Join Game' button in the app after entering room id." },
    { question: "Is Real money involved  in the game?", answer: "No, Cricket combat is free to play" },
    { question: "Can I play with friends?", answer: "Yes, you can invite friends to play and compete in multiplayer mode." },
    { question: "What devices are supported?", answer: "CricketCombat supports Android devices" },
    { question: "Are there in-game purchases?", answer: "No, the game is entirely free with no in-game purchases." }
  ];

  return (
    <div className="faq-container">
      <h2 className="faq-heading">FAQs</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggle(index)}>
            {faq.question}
            <span className="faq-icon">
              {open === index ? (
                <i className="fas fa-chevron-up"></i> // Up arrow icon
              ) : (
                <i className="fas fa-chevron-down"></i> // Down arrow icon
              )}
            </span>
          </div>
          {open === index && (
            <div className="faq-answer">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;