import '@fortawesome/fontawesome-free/css/all.min.css';
import { default as React, useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import DownloadScreen from './components/DownloadScreen';
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

const trackEvent = async (eventType) => {
  try {
    if (process.env.REACT_APP_ENV !== "production") {
      console.log(`Event tracking skipped in ${process.env.REACT_APP_ENV} mode.`);
      return;
    }

    const response = await fetch(process.env.REACT_APP_SUPABASE_EDGE_FUNCTION, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ event_type: eventType }),
    });

    if (!response.ok) {
      console.error(`Failed to track event: ${eventType}, Status: ${response.status}`);
    } else {
      console.log(`Event tracked: ${eventType}`);
    }
  } catch (error) {
    console.error(`Error tracking event: ${eventType}`, error.message);
  }
};
const App = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const hasTrackedPageView = useRef(false);
  const androidButtonRef = useRef(null);

  useEffect(() => {
    if (!hasTrackedPageView.current) {
      trackEvent("PAGE_VIEW"); // Your tracking function
      hasTrackedPageView.current = true;
    }
 
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (androidButtonRef.current) {
      observer.observe(androidButtonRef.current);
    }

    return () => {
      if (androidButtonRef.current) {
        observer.unobserve(androidButtonRef.current);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainScreen
              isButtonVisible={isButtonVisible}
              androidButtonRef={androidButtonRef}
            />
          }
        />
        <Route path="/download" element={<DownloadScreen />} />
        <Route path ="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path ="/terms-conditions" element={<TermsAndConditions/>}/>
      </Routes>
    </BrowserRouter>
  );
};

const MainScreen = ({ androidButtonRef, isButtonVisible }) => {

  const giftCardUrls = [
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpxqmy4m_ccjbbe.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/1707126858312_y2m1k7h2els8r964o_cdvmmu.png",
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpciybtI_zoxvi7.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpyR4Z72_gqidrg.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpVfhAWt_jdhgfj.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpEfm91x_xyyflg.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php5Ow4UY_kocm5t.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpyjgRJ7_tbyzic.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpNHcRjU_e5kvh0.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/image/phpUQAJOf_qzbvlq.png",                
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phprvgD1F_xz03df.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpR0yFHS_phgpbb.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpMWF3dt_z9y4xr.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpLOKNWk_uudeok.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpFiflNn_eu9q2e.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php3ZnLZY_oegsyy.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpFsL1de_fpnf3f.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phps9Oqeg_axkgnp.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpjfbyuE_vs2yti.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpilYRyN_xw5o5j.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpSHEvCH_xvfdf1.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpUGKVqx_qj01gd.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpZwncLn_tzk5jg.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpxHTz2j_h5xhgd.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php6DQ5uQ_fpwqc8.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php2NWTTs_r5qn5m.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpsoEpx8_imxhh9.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpqd2C79_ob7ki3.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpma2pkC_prbbxf.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpUcGiCr_ggekia.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phppT3gcL_ozdunn.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpdF0SCT_efn8jp.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php3F8JEK_swswln.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpBToRGA_dkatzd.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpa9evi4_wcg1ol.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpDyRhYQ_pvwrmg.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phps3TOfr_swasbp.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpf9MzHP_zkvdex.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpOPLy1H_rckmwj.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpyuEFsB_dzvuml.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpiPRQ1e_buzri7.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpvPoalu_osayde.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpRxMh1F_yrbyyc.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpoTz5Bu_tuavez.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpRWYAs2_fckzzr.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpYDtmt2_ubbnbg.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpm2SjSh_nsikxr.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpx0OdWq_iqjbvg.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpgnOK21_yh2wre.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpR8gI7m_lgqiuz.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpi54i6p_zsavpy.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php8IHXG5_dlw7mo.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpZ5m2R7_a7ax7c.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php54bQRA_dablws.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpRSOrDL_kexzlh.jpg",                       
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpSp9HRw_g3mdr3.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpfBDz16_dpclgw.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/php3NqyFm_uuuwu4.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpEv36iW_onpp9s.png",                      
    "https://res.cloudinary.com/dyyjph6kx/image/upload/fl_lossy,f_auto,q_auto,c_pad,b_white,h_280,w_420/gift_vouchers/phpP4YeoN_kyzjsw.png",  
  ];

  // Duplicate cards to enable seamless scrolling
  const allCards = [giftCardUrls.slice(0,20),giftCardUrls.slice(20,40),giftCardUrls.slice(40,60)];
  return (
    <div className='main-screen'> 
       <div className="navbar-home">
       <img src ="/cricket_combat_logo.jpg"className ='logo'/> <h1 className="navbar-title-home">CRICKET COMBAT</h1>
      </div>

      <div className="background-container">
  <img src="/background_design.png" alt="Background Design" className="background-image" />
  <Link to="/download" style={{ textDecoration: 'none' }}>
  <button className="download-button" ref={androidButtonRef} onClick={() => trackEvent("DOWNLOAD")}>
    <img src="/android_icon.png" alt="Android Icon" className="button-icon" />
    <span className="button-text">Download</span>
  </button>
</Link>
</div>

      <About />
      <div className="rewards-section">
  <div className="rewards-steps">
    <div className="step">
      <i className="fas fa-gamepad step-icon"></i>
      <h3>Play Games</h3>
      <p>Challenge your friends and win Cricket Combat Coins.</p>
    </div>
    <div className="step">
      <i className="fas fa-users step-icon"></i>
      <h3>Refer Friends</h3>
      <p>Invite friends and earn bonus coins for every referral.</p>
    </div>
    <div className="step">
      <i className="fas fa-coins step-icon"></i>
      <h3>Earn Coins</h3>
      <p>Collect Cricket Combat Coins and increase your balance.</p>
    </div>
    <div className="step">
      <i className="fas fa-gift step-icon"></i>
      <h3>Redeem Rewards</h3>
      <p>Use your coins to claim exciting gift cards and vouchers.</p>
    </div>
  </div>

  <h2 className="gift-cards-heading">Exciting Gift Cards</h2>
  <p className="gift-cards-description">Redeem Cricket Combat Coins for these amazing rewards!</p>
</div>
      <div className="gift-card-section">
      {allCards.map((row, rowIndex) => {
        // Duplicate the row for seamless scrolling
        const duplicatedRow = [...row, ...row];
        return (
          <div
            key={rowIndex}
            className={`gift-card-row ${
              rowIndex % 2 === 0 ? "move-right" : "move-left"
            }`}
          >
            {duplicatedRow.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Gift Card ${index + 1}`}
                className="gift-card"
              />
            ))}
          </div>
        );
      })}
    </div>
      <FAQ />

      <Footer />
      <div className="spacer"></div>
      {!isButtonVisible&& (
        <div className="fixed-download-box">
          <Link to="/download" className="fixed-download-link" style={{ width: '100%', height: '100%', textDecoration: 'none' }}>
            <button className="fixed-download-button" onClick={() => trackEvent("DOWNLOAD")}>
              DOWNLOAD APP
            </button>
          </Link>
        </div>
      )}
    </div>);}

export default App;