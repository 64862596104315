import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div style={{
      backgroundColor: 'black',
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1 style={{
        color: 'white',
        fontSize: '16px',
        margin: '0',
        lineHeight: '1.5'
      }}>
        © 2024 Shouryanga Technologies LLP. All Rights Reserved.
      </h1>
      <div style={{ marginTop: '10px' }}>
        <Link to="/privacy-policy" style={{
          color: 'white',
          textDecoration: 'none',
          marginRight: '15px'
        }}>
          Privacy Policy
        </Link>
        <Link to="/terms-conditions" style={{
          color: 'white',
          textDecoration: 'none'
        }}>
          Terms and Conditions
        </Link>
      </div>
    </div>
  );
}

export default Footer;